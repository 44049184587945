
.AnimationBackground {
  height : 70px;
}

.AniOutBox{
  display: inline-block;
  width: 100px;
  height: 100%;
  text-align: center;
}

.sungil {
  display: inline-block;
  width : 42px;
  height : 100%;
  background: url("sprites/sungil.png");
  background-size: auto 100%;
  animation: walkSungil 1s steps(8) infinite;
}

.teni {
  display: inline-block;
  width : 66px;
  height : 50%;
  background: url("sprites/teni.png");
  background-size: auto 100%;
  animation: walkTeni 1s steps(7) infinite;
}
.yoo {
  display: inline-block;
  width : 49px;
  height : 100%;
  background: url("sprites/yoo.png");
  background-size: auto 100%;
  animation: walkYoo 1s steps(4) infinite;
}

.dhk {
  display: inline-block;
  width : 59px;
  height : 100%;
  background: url("sprites/dhk.png");
  background-size: auto 100%;
  animation: walkDHK 1s steps(7) infinite;
}

.shin {
  display: inline-block;;
  width: 70px;
  height: 100%;
  background: url("sprites/shin.gif");
  background-size: auto 100%;
}

/* Animation keyframes for the sprite */
@keyframes walkSungil {
  100% {
    background-position: -336px 0;
  }
}
@keyframes walkTeni {
  100% {
    background-position: -462px 0;
  }
}
@keyframes walkYoo {
  100% {
    background-position: -196px 0;
  }
}

@keyframes walkDHK {
  100% {
    background-position: -413px 0;
  }
}
